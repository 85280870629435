import { Controller } from "@hotwired/stimulus"
import {getColorDisclosure} from "../src/precision_library"
import showdown from "showdown"

export default class extends Controller {
    static values = {
        materialType: String,
        brand: String,
        stoneColor: String,
        solidSurfaceColor: String,
        laminateColor: String,
        thinscapeColor: String
    }
    static targets = [
        "videoPlayer", "videoSummary",
        "image1Container", "image1", "image1Description",
        "image2Container", "image2", "image2Description",
        "image3Container", "image3", "image3Description",
    ]

    materialTypeValueChanged() {
        this.updateDisclosure()
    }

    brandValueChanged() {
        this.updateDisclosure()
    }

    stoneColorValueChanged() {
        this.updateDisclosure()
    }

    solidSurfaceColorValueChanged() {
        this.updateDisclosure()
    }

    laminateColorValueChanged() {
        this.updateDisclosure()
    }

    thinscapeColorValueChanged() {
        this.updateDisclosure()
    }

    updateDisclosure() {
        // don't needlessly reload disclosure
        if (this.materialBrand === this._previousMaterialBrand && this.materialColor === this._previousMaterialColor) {
            return
        }
        this._previousMaterialBrand = this.materialBrand
        this._previousMaterialColor = this.materialColor

        if (!this.materialBrand || !this.materialColor) {
            debugger
            // hide page
            return
        }

        getColorDisclosure(this.materialBrand, this.materialColor)
            .then(disclosure => {
                if (!disclosure) {
                    this.hideDisclosure()
                    return
                }

                this.videoURL = disclosure.videoURL
                this.videoSummary = disclosure.videoSummary
                this.image1 = disclosure.image1URL
                this.image1Description = disclosure.image1Description
                this.image2 = disclosure.image2URL
                this.image2Description = disclosure.image2Description
                this.image3 = disclosure.image3URL
                this.image3Description = disclosure.image3Description

                this.showDisclosure()
            })
            .catch(e => console.error("failed to get disclosure", e))
    }

    hideDisclosure() {
        this.page.classList.add("hidden")
    }

    showDisclosure() {
        this.page.classList.remove("hidden")
    }

    get page() {
        return this.element.closest('[data-paged-navigation-target="page"]')
    }

    set videoURL(value) {
        this.videoPlayerTarget.setAttribute("src", value)
        this.videoPlayerTarget.classList.toggle("hidden", !value)
    }

    set videoSummary(summary) {
        const converter = new showdown.Converter()
        const html = (!summary || summary === "") ? null : converter.makeHtml(summary)

        this.videoSummaryTarget.innerHTML = html
        this.videoSummaryTarget.classList.toggle("hidden", !html)
    }

    set image1(url) {
        url = url === "" ? null : url

        this.image1Target.setAttribute("src", url)
        this.image1ContainerTarget.classList.toggle("hidden", !url)
    }

    set image1Description(description) {
        description = description === "" ? null : description

        this.image1DescriptionTarget.innerText = description
        this.image1DescriptionTarget.classList.toggle("hidden", !description)
    }

    set image2(url) {
        url = url === "" ? null : url

        this.image2Target.setAttribute("src", url)
        this.image2ContainerTarget.classList.toggle("hidden", !url)
    }

    set image2Description(description) {
        description = description === "" ? null : description

        this.image2DescriptionTarget.innerText = description
        this.image2DescriptionTarget.classList.toggle("hidden", !description)
    }

    set image3(url) {
        url = url === "" ? null : url

        this.image3Target.setAttribute("src", url)
        this.image3ContainerTarget.classList.toggle("hidden", !url)
    }

    set image3Description(description) {
        description = description === "" ? null : description

        this.image3DescriptionTarget.innerText = description
        this.image3DescriptionTarget.classList.toggle("hidden", !description)
    }

    get materialType() {
        return this.materialTypeValue?.toLowerCase()
    }

    get materialBrand() {
        return this.brandValue
    }

    get materialColor() {
        switch(this.materialType) {
            case 'quartz': return this.stoneColorValue // FIXME: Is this right?
            case 'stone': return this.stoneColorValue
            case 'solid surface': return this.solidSurfaceColorValue
            case 'laminate': return this.laminateColorValue
            case 'thinscape': return this.thinscapeColorValue
            default:
                debugger
                return null
        }
    }
}