import { Controller } from "@hotwired/stimulus"
import { getSinkBrands, getSinkInfo, getSinkModels } from "../src/precision_library"

// Connects to data-controller="sink-picker"
export default class extends Controller {
  static targets = ['availabelOptions', 'loading', 'options', 'template']
  static values = {
    requiredMaterial: String,
    materialType: String,
    selectedValue: String,
  }

  materialTypeValueChanged() {
    this.loadOptions()
  }

  async loadOptions() {
    this.loadingTarget.classList.remove('hidden')
    this.availabelOptionsTarget.classList.add('hidden')
    this.optionsTarget.classList.add('hidden')
    this.optionsTarget.replaceChildren() // remove children

    const brands = (await getSinkBrands()) || []
    const models = (await Promise.all(brands.map(getSinkModels)) || []).flat()
      .filter(x => x)
      .filter(x => x.moraFinal) // No moraware IDs
      .filter(x => x.materialTypesAllowed[this.materialType])
      // .filter(x => x.bestSeller)
      // .filter(x => !x.inactive)
    // console.log('material', this.materialType)
    // console.log('filtered models', models)

    let newOptions = models.map(sink => {
      const node = this.templateTarget.content.cloneNode(true)
      node.firstElementChild.setAttribute('x-data', JSON.stringify({sink: sink}))
      const radioButton = node.querySelector('input')
      radioButton.setAttribute("checked", sink.moraName === this.selectedValueValue)

      return node
    })
    this.optionsTarget.replaceChildren(...newOptions)

    this.optionsTarget.classList.remove('hidden')
    this.loadingTarget.classList.add('hidden')
  }

  get materialType() {
    switch (this.materialTypeValue.toLowerCase()) {
      case 'solid surf':
        return 'solidSurface';
      default:
        return this.materialTypeValue.toLowerCase()
    }
  }
}
